import { useState, useEffect } from 'react';
import { IWebcast } from 'vcl-common';

const usePaginatedData = <ItemType>(
  fetchDataFunction: (page: number) => Promise<{
    items: IWebcast[];
    totalItems: number;
  }>,
  itemsPerPage: number,
) => {
  const [items, setItems] = useState<ItemType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const result = await fetchDataFunction(currentPage);
        setItems(result.items as ItemType[]);
        setTotalItems(result.totalItems);
      } catch (err) {
        setError('Failed to fetch data');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [fetchDataFunction, currentPage, itemsPerPage]);

  return [
    items,
    totalItems,
    currentPage,
    setCurrentPage,
    isLoading,
    error,
  ] as const;
};

export default usePaginatedData;
