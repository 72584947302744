import { useContext, useEffect, useState } from 'react';
import { GoGraph } from 'react-icons/go';
import { BiDotsVerticalRounded, BiShareAlt } from 'react-icons/bi';
import { FaAngleUp } from 'react-icons/fa';
import { BsGear, BsQuestionCircle } from 'react-icons/bs';
import { VscCalendar } from 'react-icons/vsc';
import Adminmenu from '../menu/adminMenu/adminmenu';
import SaveTheDate from '../menu/saveTheDate/saveTheDate';
import Share from '../menu/share/share';
import './navTab.css';
import {
  CURRENT_VIEW,
  GlobalSettingsContext,
  IGlobalSettings,
  IPageMetadata,
  IWebcast,
  VIEWING_STATE,
} from 'vcl-common';
import { isBefore } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import Statistics from '../menu/statistics/statistics';
import { useLocation } from 'react-router-dom';
import {
  hasEventGracePeriodEnded,
  hasEventStarted,
  isEventInFuture,
} from '../../utils/utils';

function NavTAb(props: {
  pageMetadata: IPageMetadata;
  isAdmin: boolean;
  isSignedIn: boolean;
  profile: any;
}) {
  const navigate = useLocation();
  const webcast: IWebcast =
    props.pageMetadata && props.pageMetadata.urlInfo
      ? props.pageMetadata.urlInfo.webcast
      : (null as any);

  const globalSettings: IGlobalSettings = useContext(GlobalSettingsContext);

  const isBeforeStart = isBefore(
    toZonedTime(new Date(), 'UTC'),
    toZonedTime(new Date(webcast?.startTime as any), 'UTC'),
  );

  const isFutureWebcast: boolean = isBeforeStart;

  const [adminToolContent, setAdminToolContent] = useState(false);
  const [askQuestionContent, setAskQuestionContent] = useState(false);
  const [shareContent, setShareContent] = useState(false);
  const [dateContent, setDateContent] = useState(false);
  const [statisticsContent, setStatisticsContent] = useState(false);

  const [upArrowAdminTool, setUpArrowAdminTool] = useState(false);
  const [upArrowAskQuest, setUpArrowAskQuest] = useState(false);
  const [upArrowShare, setUpArrowShare] = useState(false);
  const [upArrowDate, setUpArrowDate] = useState(false);
  const [upArrowStatistics, setUpArrowStatistics] = useState(false);

  const [tabAdmbinColor, setTabAdminColor] = useState(false);
  const [tabAskQuestColor, setTabAskQuestColor] = useState(false);
  const [tabShareColor, setTabShareColor] = useState(false);
  const [tabDateColor, setTabDateColor] = useState(false);
  const [tabpoup, setTabPopup] = useState(false);
  const [tabStatisticsColor, setTabStatisticsColor] = useState(false);

  const [firstLoad, setFirstLoad] = useState(true);
  const [moreButton, setMoreButton] = useState(false);
  const [moreMenuStartIndex, setMoreMenuStartIndex] = useState(4);
  // const [maxNumToolbarButtons, setMaxNumToolbarButtons] = useState(0);
  const buttons: string[] = [
    'AdminTools',
    'Calendar',
    'AskAQuestion',
    'Share',
    'Statistics',
  ];
  const [currentView, setCurrentView] = useState(CURRENT_VIEW.undef);

  useEffect(() => {
    const determineCorrectView = () => {
      if (webcast === null || webcast === undefined) return;
      const mayUserSwitchViews = props.isAdmin;
      let _currentView = CURRENT_VIEW.undef;
      if (_currentView === CURRENT_VIEW.undef) {
        // Initial views
        let viewType = null;
        if (mayUserSwitchViews) {
          // Check to see if the user is allowed to swithc views and if they have selected a certain view
          viewType = new URLSearchParams(navigate.search).get('viewType');
        }

        if (
          (isEventInFuture(webcast) && viewType !== 'webcast') ||
          viewType === 'countDown'
        ) {
          // Initial setup for countdown
          _currentView = CURRENT_VIEW.countdown;
        } else if (
          (hasEventStarted(webcast) && viewType !== 'countDown') ||
          viewType === 'webcast' ||
          (mayUserSwitchViews && webcast.viewingState === VIEWING_STATE.ended)
        ) {
          // Initial setup for video
          _currentView = CURRENT_VIEW.video;
        } else {
          _currentView = CURRENT_VIEW.ended;
        }
      } else if (webcast !== null) {
        // Do we have a view transition?
        if (hasEventStarted(webcast) && isEventInFuture(webcast)) {
          // If webcast was postponed, switch back to countdown view
          _currentView = CURRENT_VIEW.countdown;
        } else if (
          hasEventGracePeriodEnded(webcast, globalSettings?.siteSettings) &&
          webcast.viewingState === VIEWING_STATE.ended
        ) {
          // Event has ended && we are not using VOD
          // Show the ended view or do a redirect
          if (webcast.postEventBehavior === 2 && webcast.redirectUrl) {
            window.location.href = webcast.redirectUrl;
          } else if (!mayUserSwitchViews) {
            _currentView = CURRENT_VIEW.ended;
          }
        }
      }
      setCurrentView(_currentView);
    };
    determineCorrectView();
  }, [navigate.search, props.isAdmin, globalSettings, webcast]);

  const videoIsVisible = () => {
    return currentView === CURRENT_VIEW.video;
  };

  const countdownIsVisible = () => {
    return currentView === CURRENT_VIEW.countdown;
  };

  const showAdminContent = () => {
    setAdminToolContent(!adminToolContent);
    setAskQuestionContent(false);
    setShareContent(false);
    setDateContent(false);
    setStatisticsContent(false);

    setUpArrowAdminTool(!upArrowAdminTool);
    setUpArrowAskQuest(false);
    setUpArrowShare(false);
    setUpArrowDate(false);
    setUpArrowStatistics(false);

    setTabAdminColor(!tabAdmbinColor);
    setTabAskQuestColor(false);
    setTabShareColor(false);
    setTabDateColor(false);
    setTabStatisticsColor(false);
  };

  const showAskQuestionContent = () => {
    setAdminToolContent(false);
    setAskQuestionContent(!askQuestionContent);
    setShareContent(false);
    setDateContent(false);
    setStatisticsContent(false);

    setUpArrowAdminTool(false);
    setUpArrowAskQuest(!upArrowAskQuest);
    setUpArrowShare(false);
    setUpArrowDate(false);
    setUpArrowStatistics(false);

    setTabAdminColor(false);
    setTabAskQuestColor(!tabAskQuestColor);
    setTabShareColor(false);
    setTabDateColor(false);
    setTabStatisticsColor(false);
  };

  const showShareContent = () => {
    setAdminToolContent(false);
    setAskQuestionContent(false);
    setShareContent(!shareContent);
    setDateContent(false);
    setStatisticsContent(false);

    setUpArrowAdminTool(false);
    setUpArrowAskQuest(false);
    setUpArrowShare(!upArrowShare);
    setUpArrowDate(false);
    setUpArrowStatistics(false);

    setTabAdminColor(false);
    setTabAskQuestColor(false);
    setTabShareColor(!tabShareColor);
    setTabDateColor(false);
    setTabStatisticsColor(false);
  };

  const showStatisticsContent = () => {
    setAdminToolContent(false);
    setAskQuestionContent(false);
    setShareContent(false);
    setDateContent(false);
    setStatisticsContent(!statisticsContent);

    setUpArrowAdminTool(false);
    setUpArrowAskQuest(false);
    setUpArrowShare(false);
    setUpArrowDate(false);
    setUpArrowStatistics(!upArrowStatistics);

    setTabAdminColor(false);
    setTabAskQuestColor(false);
    setTabShareColor(false);
    setTabDateColor(false);
    setTabStatisticsColor(!tabStatisticsColor);
  };

  const showDateContent = () => {
    setAdminToolContent(false);
    setAskQuestionContent(false);
    setShareContent(false);
    setDateContent(!dateContent);
    setStatisticsContent(false);

    setUpArrowAdminTool(false);
    setUpArrowAskQuest(false);
    setUpArrowShare(false);
    setUpArrowDate(!upArrowDate);
    setUpArrowStatistics(false);

    setTabAdminColor(false);
    setTabAskQuestColor(false);
    setTabShareColor(false);
    setTabDateColor(!tabDateColor);
    setTabStatisticsColor(false);
  };

  const showPopup = () => {
    setTabPopup(!tabpoup);
  };

  const isAdmin = () => {
    return props.isAdmin;
  };

  const showAdminButton = () => {
    return isAdmin();
  };

  const showShareButton = () => {
    // Only if event is completely open
    return (
      videoIsVisible() &&
      !webcast.requireOrgSignIn &&
      !webcast.requireInvite &&
      !webcast.requirePin &&
      !webcast.allowExternalEmailSignin
    );
  };

  const showStatisticsButton = () => {
    //Only for the signed in user and video is visible and statistics are not hidden
    return (
      props.isSignedIn &&
      videoIsVisible() &&
      (!webcast.hideStatistics || isAdmin())
    );
  };

  const showCalendarButton = () => {
    // Only if countdown is visible
    return countdownIsVisible();
  };

  const showAskQuestionButton = () => {
    return (
      webcast.enableSlido &&
      webcast.slidoEventUrl &&
      ((currentView === CURRENT_VIEW.countdown &&
        webcast.showSlidoBeforeWebcast) ||
        (currentView === CURRENT_VIEW.video &&
          !hasEventGracePeriodEnded(webcast, globalSettings?.siteSettings) &&
          webcast.showSlidoDuringWebcast) ||
        (currentView === CURRENT_VIEW.video &&
          hasEventGracePeriodEnded(webcast, globalSettings?.siteSettings) &&
          webcast.showSlidoAfterWebcast))
    );
  };

  const getAdminToolButton = () => {
    return showAdminButton() ? (
      <div
        className={`navTab  ${
          tabAdmbinColor === true ? 'navTabFocus' : 'navTabColor'
        }`}
        onClick={showAdminContent}
        key="AdminTools"
      >
        <BsGear
          className={`navTabIcon ${
            tabAdmbinColor === true ? 'navTabColor' : ''
          }`}
        />{' '}
        Admin Tools
        {upArrowAdminTool && <FaAngleUp className="navTabIcon" />}
      </div>
    ) : null;
  };

  const getCalendarButton = () => {
    return showCalendarButton() ? (
      <div
        className={`navTab dateTab ${
          tabDateColor === true ? 'navTabFocus' : 'navTabColor'
        }`}
        onClick={showDateContent}
        key={'Calendar'}
      >
        <VscCalendar
          className={`navTabIcon ${tabDateColor === true ? 'navTabColor' : ''}`}
        />
        Save the date
        {upArrowDate && <FaAngleUp className="navTabIcon" />}
      </div>
    ) : null;
  };

  const getAskAQuestionButton = () => {
    return showAskQuestionButton() ? (
      <div
        className={`navTab askQuestionTab  ${
          tabAskQuestColor === true ? 'navTabFocus' : 'navTabColor'
        }`}
        onClick={showAskQuestionContent}
        key={'AskAQuestion'}
      >
        <BsQuestionCircle
          className={`navTabIcon ${
            tabAskQuestColor === true ? 'navTabColor' : ''
          }`}
        />
        {webcast.slidoTabHeader ? webcast.slidoTabHeader : 'Ask a question'}
        {upArrowAskQuest && <FaAngleUp className="navTabIcon" />}
      </div>
    ) : null;
  };

  const getShareButton = () => {
    return showShareButton() ? (
      <div
        className={`navTab shareTab  ${
          tabShareColor === true ? 'navTabFocus' : 'navTabColor'
        }`}
        onClick={showShareContent}
        key={'Share'}
      >
        <BiShareAlt
          className={`navTabIcon ${
            tabShareColor === true ? 'navTabColor' : ''
          }`}
        />
        Share
        {upArrowShare && <FaAngleUp className="navTabIcon" />}
      </div>
    ) : null;
  };

  const getStatisticsButton = () => {
    return showStatisticsButton() ? (
      <div
        className={`navTab statisticsTab  ${
          tabStatisticsColor === true ? 'navTabFocus' : 'navTabColor'
        }`}
        onClick={showStatisticsContent}
        key={'Statistics'}
      >
        <GoGraph
          className={`navTabIcon ${
            tabShareColor === true ? 'navTabColor' : ''
          }`}
        />
        Statistics
        {upArrowStatistics && <FaAngleUp className="navTabIcon" />}
      </div>
    ) : null;
  };

  const buttonsMap: any = {
    AdminTools: getAdminToolButton(),
    Calendar: getCalendarButton(),
    AskAQuestion: getAskAQuestionButton(),
    Share: getShareButton(),
    Statistics: getStatisticsButton(),
  };

  const activeButtons: any = {};
  for (let i = 0; i < buttons.length; i++) {
    if (buttonsMap[buttons[i]] !== null) {
      activeButtons[buttons[i]] = buttonsMap[buttons[i]];
    }
  }

  useEffect(() => {
    const getViewportWidthRange = () => {
      let width = 0;
      if (window.innerWidth) {
        width = window.innerWidth;
      } else if (document.body && document.body.offsetWidth) {
        width = document.body.offsetWidth;
      }

      if (width < 500) return 'XS';

      if (width < 700) return 'S';

      if (width < 1200) return 'M';

      return 'L';
    };

    const setupToolbar = () => {
      const numAvailableButtons =
        globalSettings?.organization?.configuration.filter(
          (config) => config.value === '1',
        ).length ?? 0;
      const hasTabs = numAvailableButtons > 0;
      let _maxNumToolbarButtons = 0,
        _moreMenuStartIndex = 6;
      let _moreButton = false;
      if (hasTabs) {
        const screenSize = getViewportWidthRange();

        switch (screenSize) {
          case 'XS':
            _maxNumToolbarButtons = 2;
            break;
          case 'S':
            _maxNumToolbarButtons = 3;
            break;
          case 'M':
            _maxNumToolbarButtons = 4;
            break;
          case 'L':
            _maxNumToolbarButtons = 4; // Toolbar is too small for more buttons in countdown mode
            break;
          default:
          // code block
        }

        if (_maxNumToolbarButtons < numAvailableButtons) {
          _moreMenuStartIndex = _maxNumToolbarButtons;
          _moreButton = true;
        } else {
          _moreMenuStartIndex = 6;
          _moreButton = false;
        }
      }

      // if (embedButton && firstLoad) {
      if (firstLoad) {
        setFirstLoad(false);
        setMoreButton(_moreButton);
        setMoreMenuStartIndex(_moreMenuStartIndex);
        // setMaxNumToolbarButtons(maxNumToolbarButtons);
      }
    };
    setupToolbar();
  }, [firstLoad, globalSettings]);

  const configureSlido = () => {
    let slidoUrl = '';
    if (webcast.enableSlido) {
      slidoUrl = webcast.slidoEventUrl;
      if (
        slidoUrl &&
        webcast.prefillSlidoCredentials &&
        props.profile &&
        props.profile.name &&
        props.profile.email
      ) {
        const name = props.profile.name;
        const email = props.profile.email;
        let company = props.profile.company;
        if (!company) {
          if (email.toLowerCase().indexOf('@volvocars.com') > 0) {
            company = 'Volvo Car Corporation';
          } else {
            company = 'Unknown';
          }
        }
        if (slidoUrl.indexOf('?') > 0) {
          slidoUrl += '&';
        } else {
          slidoUrl += '?';
        }
        slidoUrl +=
          'user_name=' +
          name +
          '&user_email=' +
          email +
          '&user_company=' +
          company;
      }
    }
    return slidoUrl;
  };

  return (
    <>
      {props.pageMetadata && props.pageMetadata.urlInfo && webcast && (
        <div className="navTabContainer">
          <div className="navTabButtons">
            <div className="navTabs">
              {Object.keys(activeButtons)
                .slice(
                  0,
                  Math.min(
                    Object.keys(activeButtons).length,
                    moreMenuStartIndex,
                  ),
                )
                .map((key) => {
                  return <div key={key}>{activeButtons[key]}</div>;
                })}
            </div>
            <div className="navTab navTabDots" onClick={showPopup}>
              {moreButton && <BiDotsVerticalRounded />}
              {tabpoup && (
                <div className="navTabPopupContainer">
                  <div className="navTabPopup">
                    {Object.keys(activeButtons)
                      .slice(
                        Math.min(
                          Object.keys(activeButtons).length,
                          moreMenuStartIndex,
                        ),
                        Object.keys(activeButtons).length,
                      )
                      .map((key) => {
                        return <div key={key}>{activeButtons[key]}</div>;
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* for content pages */}
          {adminToolContent && (
            <div className="adminContent">
              <div className="innerAdminContent">
                {' '}
                <Adminmenu
                  webcast={webcast}
                  isFutureWebcast={isFutureWebcast}
                />
              </div>
            </div>
          )}
          {dateContent && (
            <div className="navTabContent">
              <SaveTheDate
                pageMetadata={props.pageMetadata}
                webcast={props.pageMetadata.urlInfo.webcast}
              />
            </div>
          )}
          {askQuestionContent && (
            <div className="vl-interaction-embed">
              <iframe
                className="vl-slido-iframe"
                src={configureSlido()}
                title="Ask a question"
              ></iframe>
            </div>
          )}

          {shareContent && (
            <div className="navTabContent">
              <Share
                webcast={props.pageMetadata.urlInfo.webcast}
                url={props.pageMetadata.urlInfo?.url ?? ''}
              />
            </div>
          )}

          {statisticsContent && (
            <div className="navTabContent">
              <Statistics
                configuration={globalSettings?.organization?.configuration}
                webcast={webcast}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default NavTAb;
