import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './signin.css';
import { useState } from 'react';

type SigninProps = {
  signInType: string;
  emailSignInCallback: (event: any) => Promise<void>;
  pinSignInCallback: (event: any) => Promise<void>;
  isForbidden: boolean;
  emailVerified: boolean;
  emailError: boolean;
  emailTriggered: boolean;
};
function Signin({
  signInType,
  emailSignInCallback,
  pinSignInCallback,
  isForbidden,
  emailVerified,
  emailError,
  emailTriggered,
}: SigninProps) {
  const [pinCode, setPinCode] = useState('');
  const [pinError, setPinError] = useState('');
  const [formValues, setFormValues] = useState({
    fullname: '',
    email: '',
    companyName: '',
  });
  const [formError, setFormError] = useState<{ [key: string]: string }>({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const updatedFormValues = { ...formValues, [name]: value };
    setFormValues(updatedFormValues);
    validateForm(updatedFormValues);
  };

  const validateForm = (currentFormValues: typeof formValues) => {
    const errors: { [key: string]: string } = {};
    const isFormEmpty = Object.values(currentFormValues).some(
      (value) => value === '',
    );
    setIsButtonDisabled(isFormEmpty);

    if (
      currentFormValues.fullname.length < 4 ||
      currentFormValues.fullname.length > 50
    ) {
      errors.fullname = 'Use 4-50 letters.';
    }

    const emailRegEx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!emailRegEx.test(currentFormValues.email)) {
      errors.email = 'Please enter a valid email address.';
    }

    if (
      currentFormValues.companyName.length < 4 ||
      currentFormValues.companyName.length > 50
    ) {
      errors.companyName = 'Use 4-50 letters.';
    }

    setFormError(errors);
    return !isFormEmpty && Object.keys(errors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm(formValues)) {
      emailSignInCallback(e);
    }
  };

  const validatePinCode = (code: string) => {
    const pinCodeRegEx = /^\d{6}$/;
    if (!code) {
      setPinError('The PinCode field is required.');
      return false;
    } else if (!pinCodeRegEx.test(code)) {
      setPinError('Enter a valid 6-digit PIN code.');
      return false;
    } else {
      setPinError('');
      return true;
    }
  };

  const handlePinSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validatePinCode(pinCode)) {
      pinSignInCallback(e);
    }
  };

  return (
    <>
      <div className="signinTitleContainer">
        <div className="pinDateNtime">5/7/2024 at 08:35 AM</div>
        <div className="pinTitle">external pin</div>
      </div>
      <div className="signinContainer">
        {/* PIN Login Section */}
        {(['pin', 'orgpin'].includes(signInType) ||
          (emailVerified &&
            ['emailpin', 'orgemailpin'].includes(signInType))) && (
          <>
            <svg height="25" width="30" id="pinCodeArrow">
              <polygon
                points="15,15 5,25 25,25"
                fill="#3D5881"
                className="triangle"
              ></polygon>
            </svg>
            <div className="formContainer">
              <Form onSubmit={handlePinSubmit}>
                <Form.Group className="mb-4" controlId="pinCode">
                  <Form.Control
                    type="number"
                    placeholder="PIN Code"
                    value={pinCode}
                    onChange={(e) => {
                      setPinCode(e.target.value);
                      validatePinCode(e.target.value);
                    }}
                  />
                  {pinError && <p className="validateMessage">{pinError}</p>}
                  {isForbidden && !pinError && (
                    <Form.Text className="validateMessage">
                      Incorrect PIN Code
                    </Form.Text>
                  )}
                  <div className="button-container send">
                    <Button type="submit" disabled={!pinCode}>
                      SEND
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            </div>
          </>
        )}

        {/* Email Login Section */}
        {(['email', 'orgemail'].includes(signInType) ||
          (!emailVerified &&
            ['emailpin', 'orgemailpin'].includes(signInType))) && (
          <>
            {!isForbidden && !emailTriggered && !emailError && (
              <div className="formContainer">
                {
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-4" controlId="fullname">
                      <Form.Control
                        type="text"
                        name="fullname"
                        placeholder="Full Name (required)"
                        value={formValues.fullname}
                        onChange={handleChange}
                      />
                      {formError.fullname && (
                        <Form.Text className="validateMessage">
                          {formError.fullname}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="email">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email (required)"
                        value={formValues.email}
                        onChange={handleChange}
                      />
                      {formError.email && (
                        <Form.Text className="validateMessage">
                          {formError.email}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="companyName">
                      <Form.Control
                        type="text"
                        name="companyName"
                        placeholder="Company (required)"
                        value={formValues.companyName}
                        onChange={handleChange}
                      />
                      {formError.companyName && (
                        <Form.Text className="validateMessage">
                          {formError.companyName}
                        </Form.Text>
                      )}
                    </Form.Group>
                    <div className="button-container">
                      <Button
                        type="submit"
                        className={`${
                          isButtonDisabled ? 'disabled' : 'btn-primary'
                        }`}
                        disabled={isButtonDisabled}
                      >
                        SIGN IN
                      </Button>
                    </div>
                  </Form>
                }
              </div>
            )}
            <div>
              {isForbidden && (
                <div className="errorText">
                  This email address is not authorized to watch this webcast. If
                  you were invited, please contact the organizer and request
                  access.
                </div>
              )}
              {emailError && (
                <div className="errorText">
                  An error occurred while verifying your email. Please try
                  again.
                </div>
              )}
              {emailTriggered && !emailError && (
                <div className="successText">
                  Please check your inbox for the verification email. Click on
                  the link in the email to sign in to the webcast.
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Signin;
