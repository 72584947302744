import { createRoot } from 'react-dom/client';
import App from './App';
import { loginRequest, msalConfig } from './utils/msalConfig';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './master.css';
import { AuthContext, MSALAuthService } from 'vcl-common';

const container: any = document.getElementById('root');
const root = createRoot(container);
root.render(
  <AuthContext.Provider
    value={{
      authService: new MSALAuthService(msalConfig, loginRequest),
    }}
  >
    <App />
  </AuthContext.Provider>,
);

reportWebVitals();
