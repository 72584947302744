import '@flowplayer/player/flowplayer.css';

import React, { useEffect, useRef, useState } from 'react';
import Flowplayer, { useFlowplayer } from '@flowplayer/react-flowplayer';
import flowplayer from '@flowplayer/player';
import HLSPlugin from '@flowplayer/player/plugins/hls';
import KeyboardPlugin from '@flowplayer/player/plugins/keyboard';
import ThumbnailsPlugin from '@flowplayer/player/plugins/thumbnails';
import QualityPlugin from '@flowplayer/player/plugins/qsel';
import SpeedPlugin from '@flowplayer/player/plugins/speed';
import ChromecastPlugin from '@flowplayer/player/plugins/chromecast';
import AirplayPlugin from '@flowplayer/player/plugins/airplay';
import FloatOnScrollPlugin from '@flowplayer/player/plugins/float-on-scroll';
import Shareplugin from '@flowplayer/player/plugins/share';

import { ENDED, PAUSE, PLAYING } from '@flowplayer/player/core/events';
import { getHostDependentImageUrl, IWebcast } from 'vcl-common';

// Register plugins
flowplayer(HLSPlugin);
flowplayer(KeyboardPlugin);
flowplayer(ThumbnailsPlugin);
flowplayer(QualityPlugin);
flowplayer(SpeedPlugin);
flowplayer(ChromecastPlugin);
flowplayer(AirplayPlugin);
flowplayer(FloatOnScrollPlugin);
flowplayer(Shareplugin);

type VideoPlayerProps = {
  webcast?: IWebcast;
};

const VideoPlayer = ({ webcast }: VideoPlayerProps) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const playerApi = useFlowplayer(playerRef);

  const [demoPlaybackState, setDemoPlaybackState] = useState('paused');
  const [config, setConfig] = useState<any | null>(null);
  const PLAYER_ID = '0386ad2c-b6e3-4dc4-9c28-88db6e6f9055';
  const DEMO_TOKEN =
    'eyJraWQiOiI3dVFjM2pGbU9lNVAiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6MzgsXCJpZFwiOlwiN3VRYzNqRm1PZTVQXCJ9IiwiaXNzIjoiRmxvd3BsYXllciJ9.WihkBshkBCmCm1CpDY36kK3VHJbGLhIg8hTbHD4-ZcCITCpP61-D1pGOtUNDAH1-zC4XIpiyixHYAUycwbJIGw';

  const onHandleState = (ev: Event) => {
    if (ev.type === PAUSE) setDemoPlaybackState('paused');
    if (ev.type === PLAYING) setDemoPlaybackState('playing');
    if (ev.type === ENDED) setDemoPlaybackState('ended');
  };

  const getPlaylistUrl = () => {
    let playlistUrl = '';

    // If webcast uses Wowza Video and is live
    if (webcast?.wvStreamIsLive) {
      if (webcast?.hlsPublicLiveUrl) {
        // If user is off-site
        playlistUrl = webcast?.hlsPublicLiveUrl;
      }
    }
    // If event uses Wowza Video and has a VOD recording
    else if (webcast?.hlsPublicVodUrl) {
      playlistUrl = webcast?.hlsPublicVodUrl;
    }

    return playlistUrl;
  };

  useEffect(() => {
    (async () => {
      // Fetch player config
      const config = await fetch(
        'https://ljsp.lwcdn.com/web/public/native/config/' + PLAYER_ID,
      );
      const playerConfig = await config.text();
      setConfig(playerConfig);
    })();
  }, []);

  // Listen to player events for the demo
  useEffect(() => {
    if (!playerApi) return;
    playerApi.on([PAUSE, PLAYING, ENDED], onHandleState);
    playerApi.togglePlay();
    return () => {
      // Cleanup on unmount
      if (!playerApi) return;
      playerApi.off(PAUSE, onHandleState);
      playerApi.off(PLAYING, onHandleState);
      playerApi.off(ENDED, onHandleState);
    };
  }, [playerApi]);

  return (
    <>
      <Flowplayer
        src={getPlaylistUrl()}
        token={DEMO_TOKEN}
        ref={playerRef}
        opts={{
          autoplay: demoPlaybackState === 'ended' ? false : true,
          poster: getHostDependentImageUrl(webcast?.thumbnailUrl),
          ...config,
          ratio: '16:9',
        }}
      />
    </>
  );
};

export default VideoPlayer;
