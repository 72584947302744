import {
  PageMetadataContext,
  useFeed,
  getHostDependentImageUrl,
  IWebcast,
} from 'vcl-common';
import Hero from '../../sharedComponents/hero/hero';
import TakeoverThumbnail from '../../sharedComponents/TakeoverThumbnail/TakeoverThumbnail';
import Carousel from '../../sharedComponents/thumbnail/carousel';
import { useContext, useCallback, useState, useEffect } from 'react';

function Channel() {
  const [initialItem, setInitialItem] = useState<IWebcast | null>(null);
  const [subsequentItems, setSubsequentItems] = useState<any[]>([]);

  const fetchFeed = useFeed();
  const pageMetadata = useContext(PageMetadataContext);
  const feedSizeConf =
    pageMetadata?.globalSettings?.organization?.configuration.filter(
      (config) => config.key === 'RollupNoOfThumbnails',
    );
  const rollupNoOfThumbnails =
    feedSizeConf && feedSizeConf.length > 0 ? Number(feedSizeConf[0].value) : 4;

  const fetchUpcomingItems = useCallback(
    async (page: number) => {
      if (pageMetadata?.globalSettings?.siteSettings) {
        const result = await fetchFeed(
          'channel',
          'channel',
          Number(pageMetadata?.globalSettings?.organization?.id),
          'future',
          0,
          pageMetadata?.urlInfo?.channel.id,
          page,
          rollupNoOfThumbnails,
        );
        if (page === 1 && result.items.length > 0 && initialItem === null) {
          setInitialItem(result.items[0]);
          setSubsequentItems(
            result.items.length > 1 ? result.items.slice(1) : [],
          );
        } else if (page > 1) {
          setSubsequentItems((prevItems) => [...prevItems, ...result.items]);
        }
        return result;
      }
      return { items: [], totalItems: 0 };
    },
    [fetchFeed, initialItem, pageMetadata, rollupNoOfThumbnails],
  );

  const fetchRecommendedItems = useCallback(
    async (page: number) => {
      if (pageMetadata?.globalSettings?.siteSettings) {
        return await fetchFeed(
          'channel',
          'channel',
          Number(pageMetadata?.globalSettings?.organization?.id),
          'past',
          0,
          pageMetadata?.urlInfo?.channel.id,
          page,
          rollupNoOfThumbnails,
        );
      }
      return { items: [], totalItems: 0 };
    },
    [pageMetadata, fetchFeed, rollupNoOfThumbnails],
  );

  return (
    <>
      <Hero
        heroImage={getHostDependentImageUrl(
          pageMetadata?.urlInfo?.channel.heroUrl,
        )}
        imgtext={pageMetadata?.urlInfo?.channel.title}
        descText={pageMetadata?.urlInfo?.channel}
      />
      {initialItem && <TakeoverThumbnail item={initialItem} />}
      {/* futureEvent */}
      <Carousel
        headerTitle="Upcoming webcasts"
        items={subsequentItems}
        fetchItems={fetchUpcomingItems}
        isFromChannel={true}
      />
      {/* pastEvents */}
      <Carousel
        headerTitle="Latest webcasts"
        fetchItems={fetchRecommendedItems}
        isFromChannel={true}
      />
    </>
  );
}

export default Channel;
