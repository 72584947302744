import NavTAb from '../../sharedComponents/navTab/navTab';
import './webcast.css';
import {
  AuthContext,
  Constants,
  IPageMetadata,
  IWebcast,
  MSALAuthService,
  PageMetadataContext,
  VIEWING_STATE,
  getHostDependentImageUrl,
  useFeed,
} from 'vcl-common';
import { useCallback, useContext, useEffect, useState } from 'react';
import Countdown from '../../sharedComponents/countdown/countdown';
import Carousel from '../../sharedComponents/thumbnail/carousel';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { statsMinimal } from '../../sharedComponents/menu/statistics/statsMinimal';
import { isEventInFuture } from '../../utils/utils';
import Markdown from 'react-markdown';
import VideoPlayer from '../player/videoPlayer';

export function formatDateAndTime(dateTimeString: string, includeTime = true) {
  const date = new Date(dateTimeString);
  const formattedDate = format(date, 'MM/dd/yyyy');
  const formattedTime = format(date, 'hh:mm a');
  return includeTime ? `${formattedDate} at ${formattedTime}` : formattedDate;
}

function Webcast() {
  // we need to change this to network call in the future
  const stats = statsMinimal;
  const noOfOnlineViewers = stats.OnlineViewers ?? 0;
  const authContext = useContext(AuthContext);
  const authService = authContext.authService as MSALAuthService;
  const [isLoading, setIsLoading] = useState(false);
  const pageMetadata = useContext<IPageMetadata>(PageMetadataContext);
  const navigate = useLocation();
  const viewType = new URLSearchParams(navigate.search).get('viewType');
  const webcastEndGracePeriodMinutes =
    pageMetadata.globalSettings &&
    pageMetadata.globalSettings.siteSettings &&
    pageMetadata.globalSettings.siteSettings.WebcastEndGracePeriodMinutes
      ? pageMetadata.globalSettings.siteSettings.WebcastEndGracePeriodMinutes *
        60 *
        1000
      : 5000;

  const [isFutureWebcast, setIsFutureWebcast] = useState(
    pageMetadata.urlInfo?.webcast
      ? isEventInFuture(pageMetadata.urlInfo?.webcast)
      : false,
  );
  const isCountDown =
    (viewType === null && isFutureWebcast) || viewType === 'countDown';

  const fetchFeed = useFeed();
  const [isSignedIn, setIsSignedIn] = useState(false);

  const [profile, setProfile] = useState({
    id: '',
    name: '',
    email: '',
    company: '',
  });
  const feedSizeConf =
    pageMetadata?.globalSettings?.organization?.configuration.filter(
      (config) => config.key === 'RollupNoOfThumbnails',
    );
  const rollupNoOfThumbnails =
    feedSizeConf && feedSizeConf.length > 0 ? Number(feedSizeConf[0].value) : 4;
  const webcast: IWebcast = pageMetadata.urlInfo?.webcast
    ? pageMetadata.urlInfo?.webcast
    : (null as any);

  useEffect(() => {
    (async () => {
      if (!isSignedIn) {
        const isLoggedIn = await authService.isSignedIn();
        setIsSignedIn(isLoggedIn);
        if (isLoggedIn) {
          const currentUserRepsonse: any = localStorage.getItem(
            Constants.localStorage.currentUser,
          );
          const _user: any = JSON.parse(currentUserRepsonse);
          if (_user) {
            setProfile({
              id: _user.id,
              name: _user.displayName,
              email: _user.mail,
              company: _user.companyName,
            });
          }
        }
      }
      setIsLoading(false);
    })();
  }, [authService, isSignedIn]);

  const videoIsVisible = () => {
    return (!isFutureWebcast && !isCountDown) || viewType === 'webcast';
  };

  const countdownIsVisible = () => {
    return (isFutureWebcast && viewType !== 'webcast') || isCountDown;
  };

  const isAdmin = () => {
    return pageMetadata.webcastAdmins
      ? (pageMetadata.webcastAdmins?.filter(
          (_admin: string) => _admin === profile.id,
        ).length > 0 ?? false)
      : false;
  };

  const endedInfoIsVisible = () => {
    return (
      !(isSignedIn && isAdmin()) &&
      webcast &&
      webcast.viewingState === VIEWING_STATE.ended &&
      webcast.postEventBehavior === 0
    );
  };

  const fetchChannelItems = useCallback(
    async (page: number) => {
      if (pageMetadata?.globalSettings?.organization) {
        return await fetchFeed(
          'webcast',
          'webcast',
          Number(pageMetadata?.globalSettings?.organization?.id),
          'past',
          Number(pageMetadata.urlInfo?.webcast?.id),
          0,
          page,
          rollupNoOfThumbnails,
        );
      }
      return { items: [], totalItems: 0 };
    },
    [fetchFeed, pageMetadata, rollupNoOfThumbnails],
  );

  const fetchRecommendedItems = useCallback(
    async (page: number) => {
      if (pageMetadata?.globalSettings?.organization) {
        return await fetchFeed(
          'webcast',
          'latest',
          Number(pageMetadata?.globalSettings?.organization?.id),
          'past',
          Number(pageMetadata.urlInfo?.webcast?.id),
          0,
          page,
          rollupNoOfThumbnails,
        );
      }
      return { items: [], totalItems: 0 };
    },
    [fetchFeed, pageMetadata, rollupNoOfThumbnails],
  );

  const showStatistics = () => {
    return !webcast.hideStatistics || isAdmin();
  };

  const displayWebcastDetails = () => {
    return (
      <>
        {!webcast ? (
          <></>
        ) : (
          <div className="vl-videoInfo vl-always-center">
            {showStatistics() &&
              webcast.viewingState <= 1 &&
              noOfOnlineViewers > 0 && (
                <div className="vl-views" id="viewCounter">
                  {noOfOnlineViewers} watching
                </div>
              )}
            {showStatistics() && webcast.viewingState > 1 && (
              <pre className="vl-views"> {webcast.viewCount ?? 0} views</pre>
            )}
            <div className="vl-broadcastDate">
              <span>
                {webcast?.startTime &&
                  formatDateAndTime(webcast.startTime, false)}
              </span>
              {webcast.channel && webcast.channel.title && (
                <span>
                  <a
                    href={`/${webcast.channel.pathName}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {webcast.channel.title}
                  </a>
                </span>
              )}
            </div>
            <h2 className="vl-title1">{webcast?.title}</h2>
            <h2 className="vl-title2">
              {webcast && webcast?.title2 ? ` ${webcast.title2}` : ''}
            </h2>
            {webcast.descriptionFieldContainsHTML ? (
              <div
                className="vl-discription"
                dangerouslySetInnerHTML={{ __html: webcast?.descriptionAsHTML }}
              />
            ) : (
              <div className="vl-discription">
                <Markdown>{webcast?.description}</Markdown>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const showWebcastEndedMessage = () => {
    return (
      <section id="endedSec">
        <div className="vl-ended">
          <div className="vl-Alert--info vl-u-center-inline">
            {Constants.webcast.webcastEndedMessage}
            <br />
            {Constants.webcast.webcastEndedContactInfoPrefix}{' '}
            <a href={`mailto:${webcast.contactEmail}`}>
              {webcast.contactEmail}
            </a>
          </div>
        </div>
      </section>
    );
  };

  const shouldRedirectWebcast = () => {
    return (
      webcast &&
      webcast.viewingState === VIEWING_STATE.endedredirect &&
      webcast.postEventBehavior === 2
    );
  };

  const displayCountdownTimer = () => {
    return (
      <div className="countdown">
        <pre>
          {webcast?.startTime && formatDateAndTime(webcast.startTime)}
          {showStatistics() &&
            webcast.viewingState < 2 &&
            noOfOnlineViewers > 0 &&
            ` | ${noOfOnlineViewers} waiting`}
        </pre>
        <h1 className="title">{webcast?.titleCombined}</h1>
        <Countdown
          startDate={new Date(webcast?.startTime as any)}
          customDate={
            webcast?.useCustomCDSwitchOver === true
              ? new Date(
                  pageMetadata.urlInfo?.webcast?.customCDSwitchOverTime as any,
                )
              : null
          }
          switchToStream={() => {
            setIsFutureWebcast(false);
          }}
        />
      </div>
    );
  };

  const displayCountdownDescription = () => {
    return webcast.lobbyDescription && webcast.lobbyDescription.length > 0 ? (
      webcast.lobbyDescriptionFieldContainsHTML ? (
        <div
          className="discriptionHTML"
          dangerouslySetInnerHTML={{
            __html: webcast?.lobbyDescriptionAsHTML,
          }}
        />
      ) : (
        <div className="vl-discription">{webcast?.lobbyDescription} </div>
      )
    ) : webcast.descriptionFieldContainsHTML ? (
      <div
        className="vl-discription"
        dangerouslySetInnerHTML={{ __html: webcast?.descriptionAsHTML }}
      />
    ) : (
      <div className="vl-discription">
        <Markdown>{webcast?.description}</Markdown>
      </div>
    );
  };

  const redirectWebcast = () => {
    const timer = setTimeout(() => {
      window.location.href = webcast.redirectUrl;
    }, webcastEndGracePeriodMinutes);

    return () => clearTimeout(timer);
  };

  const canShowCTA = () => {
    return (
      videoIsVisible() && webcast.enableCTAButton && webcast.showCTAButtonInVod
      // webcast.vodFirstPublished === null
    );
  };

  const showCTA = () => {
    return (
      <>
        <div className="cta-container">
          <div className="cta-text">
            <p>{webcast.ctaButtonHeadline}</p>
            <div className="ctaDescrip">{webcast.ctaDescriptionAsHTML}</div>
          </div>
          <button
            className="cta-button"
            onClick={() => window.open(webcast.ctaButtonUrl, '_blank')}
          >
            {webcast.ctaButtonTitle}
          </button>
        </div>

        <div className="horizontal-line"></div>
      </>
    );
  };

  return (
    <>
      {isLoading || !webcast ? (
        <></>
      ) : (
        <>
          {!endedInfoIsVisible() && (
            <div className="videoContainer">
              {countdownIsVisible() ? (
                displayCountdownTimer()
              ) : (
                <>
                  {(pageMetadata.urlInfo?.webcast &&
                    pageMetadata.urlInfo?.webcast?.hlsPublicLiveUrl) ||
                  pageMetadata.urlInfo?.webcast.hlsPublicVodUrl ? (
                    <VideoPlayer webcast={pageMetadata.urlInfo?.webcast} />
                  ) : (
                    <img
                      alt="video player"
                      src={getHostDependentImageUrl(
                        pageMetadata.urlInfo?.webcast?.thumbnailUrl,
                      )}
                    />
                  )}
                </>
              )}
            </div>
          )}

          {!endedInfoIsVisible() && (
            <NavTAb
              pageMetadata={pageMetadata}
              isAdmin={isAdmin()}
              isSignedIn={isSignedIn}
              profile={profile}
            />
          )}

          {!endedInfoIsVisible() && (
            <div className="titlesContainer">
              {canShowCTA() && showCTA()}

              {countdownIsVisible() ? (
                displayCountdownDescription()
              ) : (
                <>{videoIsVisible() && <div>{displayWebcastDetails()}</div>}</>
              )}
            </div>
          )}

          {/* we need to show the recommended webcasts only if the event is live or past.*/}
          {videoIsVisible() && !endedInfoIsVisible() && (
            <>
              {/* channelEvents */}
              {webcast?.channel && (
                <Carousel
                  headerTitle={`More from ${
                    pageMetadata.urlInfo?.webcast?.channel?.title ??
                    'Volvo Cars'
                  }`}
                  fetchItems={fetchChannelItems}
                />
              )}

              {/* recommended */}
              <Carousel
                headerTitle="Recommended"
                fetchItems={fetchRecommendedItems}
              />
            </>
          )}

          {/* Ended webcast message */}
          {endedInfoIsVisible() && showWebcastEndedMessage()}

          {/* Redirect after a webcast ends */}
          {shouldRedirectWebcast() && redirectWebcast()}
        </>
      )}
    </>
  );
}

export default Webcast;
